import moment from 'moment';
import i18n from 'i18n';
import { formatCurrency, formatLanguage, formatList } from 'utils/format';
import { DataByAction } from './logs.types';

const getTranslationParams = ({ action, data }: DataByAction) => {
  switch (action) {
    case 'benefitCreated':
      return {
        benefitName: data.benefitName,
        value: data.benefitValue,
        isBalanceTrackingEnabled: data.isTrackBalanceEnabled,
        allowClaimReimbursement: data.allowClaimReimbursement,
        activatedAt: data.activatedAt,
        deactivatedAt: data.deactivatedAt,
      };
    case 'benefitEdited':
      return {
        benefitName: data.benefitName,
        value: data.benefitValue,
        isBalanceTrackingEnabled: data.isTrackBalanceEnabled,
        allowClaimReimbursement: data.allowClaimReimbursement,
        activatedAt: moment(data.activatedAt).format('l'),
        deactivatedAt: moment(data.deactivatedAt).format('l'),
      };
    case 'benefitToGroupStatusChange':
      return {
        benefitName: data.benefitName,
        groupName: data.groupName,
        status: i18n.t(`status.${data.status}`),
        fromDate: moment(data.fromDate).format('l'),
      };
    case 'benefitToEmployeeStatusChange':
      return {
        benefitName: data.benefitName,
        employeeFullName: data.employeeFullName,
        status: i18n.t(`status.${data.status}`),
        fromDate: moment(data.fromDate).format('l'),
      };
    case 'benefitDeleted':
      return {
        benefitName: data.benefitName,
      };
    case 'alternativeCreated':
      return {
        alternativeName: data.alternativeName,
        isActive: data.isActive,
        deadlineInDays: data.deadlineInDays,
        deadlineFrom: moment(data.deadlineFrom).format('l'),
        deadlineTo: moment(data.deadlineTo).format('l'),
      };
    case 'alternativeUpdated':
      return {
        alternativeName: data.alternativeName,
        isActive: data.isActive,
        deadlineInDays: data.deadlineInDays,
        deadlineFrom: moment(data.deadlineFrom).format('l'),
        deadlineTo: moment(data.deadlineTo).format('l'),
      };
    case 'alternativeAssignedToGroup':
      return {
        alternativeName: data.alternativeName,
        groupName: data.groupName,
      };
    case 'alternativeAssignedToEmployee':
      return {
        alternativeName: data.alternativeName,
        employeeFullName: data.employeeFullName,
      };
    case 'alternativeRemovedFromGroup':
      return {
        alternativeName: data.alternativeName,
        groupName: data.groupName,
      };
    case 'alternativeRemovedFromEmployee':
      return {
        alternativeName: data.alternativeName,
        employeeFullName: data.employeeFullName,
      };
    case 'alternativeDeleted':
      return {
        alternativeName: data.alternativeName,
      };
    case 'alternativeBenefitAssignedToEmployee':
      return {
        benefitName: data.benefitName,
        employeeFullName: data.employeeFullName,
        alternativeName: data.alternativeName,
      };
    case 'alternativeSelectionLocked':
      return {
        benefitName: data.benefitName,
        employeeFullName: data.employeeFullName,
        alternativeName: data.alternativeName,
      };
    case 'employeeCreated':
      return {
        employeeFullName: data.employeeFullName,
        email: data.email,
        phone: data.phone,
        dateOfBirth: data.dateOfBirth,
        startDate: moment(data.startDate).format('l'),
        companyName: data.companyName,
        employmentDate: moment(data.employmentDate).format('l'),
        endDate: moment(data.endDate).format('l'),
        departmentName: data.departmentName,
        jobTitle: data.jobTitle,
        employeeId: data.internalEmployeeId,
      };
    case 'employeeUpdated':
      return {
        employeeFullName: data.employeeFullName,
        email: data.email,
        phone: data.phone,
        dateOfBirth: data.dateOfBirth,
        startDate: moment(data.startDate).format('l'),
        companyName: data.companyName,
        employmentDate: moment(data.employmentDate).format('l'),
        endDate: moment(data.endDate).format('l'),
        departmentName: data.departmentName,
        jobTitle: data.jobTitle,
        employeeId: data.internalEmployeeId,
      };
    case 'employeeAddedToGroup':
      return {
        employeeFullName: data.employeeFullName,
        groupName: data.groupName,
        fromDate: moment(data.fromDate).format('l'),
      };
    case 'employeeRemovedFromGroup':
      return {
        employeeFullName: data.employeeFullName,
        groupName: data.groupName,
        fromDate: moment(data.fromDate).format('l'),
      };
    case 'employeeDeleted':
      return {
        employeeFullName: data.employeeFullName,
      };
    case 'employeeGroupCreated':
      return {
        groupName: data.groupName,
      };
    case 'employeeGroupUpdated':
      return {
        oldName: data.oldName,
        newName: data.newName,
      };
    case 'employeeGroupDeleted':
      return {
        groupName: data.groupName,
      };
    case 'recognitionSettingsUpdated':
      return {
        useMoney: data.useMoney,
        currencyToPoint: data.currencyToPoint,
        pointRewardPerRecognition: data.pointRewardPerRecognition,
      };
    case 'recognitionAllowanceCreated':
      return {
        groupNames: formatList(i18n.language, data.groupNames),
        points: data.points,
        frequency: data.frequency,
      };
    case 'recognitionAllowanceUpdated':
      return {
        groupNames: formatList(i18n.language, data.groupNames),
        points: data.points,
        frequency: data.frequency,
      };
    case 'recognitionAllowanceDeleted':
      return {
        groupNames: formatList(i18n.language, data.groupNames),
        points: data.points,
        frequency: data.frequency,
      };
    case 'recognitionPointsImported':
      return {};
    case 'recognitionUpdated':
      return {
        employeeFullName: data.employeeFullName,
        points: data.points,
        comment: data.comment,
      };
    case 'recognitionDeleted':
      return {
        employeeFullName: data.employeeFullName,
        points: data.points,
        comment: data.comment,
      };
    case 'expenseCreated':
      return {
        employeeFullName: data.employeeFullName,
        date: moment(data.date).format('l'),
        sourceBenefits: formatList(i18n.language, data.sourceBenefitsNames),
        sourceRecognition: data.hasRecognitionReward,
        expense: data.expense,
      };
    case 'expenseUpdated':
      return {
        employeeFullName: data.employeeFullName,
        date: moment(data.date).format('l'),
        sourceBenefits: formatList(i18n.language, data.sourceBenefitsNames),
        sourceRecognition: data.hasRecognitionReward,
        expense: data.expense,
      };
    case 'hrAdminCreated':
      return {
        name: data.hrAdminFullName,
        email: data.email,
        phone: data.phone,
      };
    case 'hrAdminDeleted':
      return {
        name: data.hrAdminFullName,
        email: data.email,
        phone: data.phone,
      };
    case 'hrAdminLoggedIn':
      return {
        name: data.hrAdminFullName,
      };
    case 'employeeLoggedIn':
      return {
        employeeFullName: data.employeeFullName,
      };
    case 'employeeSelectedAlternativeBenefit':
      return {
        employeeFullName: data.employeeFullName,
        benefitName: data.benefitName,
        alternativeName: data.alternativeName,
      };
    case 'employeePlacedMarketplaceOrder':
      return {
        employeeFullName: data.employeeFullName,
        orderNr: data.marketplaceOrderNr,
      };
    case 'melpChangedMarketplaceOrderStatus':
      return {
        employeeFullName: data.employeeFullName,
        orderNr: data.marketplaceOrderNr,
        status: i18n.t(`marketplaceOrders.status-${data.status}`),
      };
    case 'automationRuleSuccess':
      return {
        ruleName: data.ruleName,
        count: data.count,
      };
    case 'automationRuleError':
      return {
        ruleName: data.ruleName,
      };
    case 'internalItemCreated':
      return {
        name: data.name,
        prices: formatList(
          i18n.language,
          data.prices.map((price) =>
            [
              price.title || '-',
              formatCurrency(i18n.language, price.price, data.currency),
            ].join(' '),
          ),
        ),
        status: i18n.t(`status.${data.status}`),
        trackInventory: data.trackInventory
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
        purchaseLimits:
          data.purchaseLimitPeriodType === 'none'
            ? i18n.t('common.no')
            : [
                i18n.t(
                  `shop.internal.purchaseLimit.${data.purchaseLimitPeriodType}`,
                ),
                data.purchaseLimit,
                i18n.t(
                  `recognition.allowanceRuleFrequency-${data.purchaseLimitPeriod}`,
                ),
              ].join(' '),
      };
    case 'internalItemUpdated':
      return {
        name: data.name,
        prices: formatList(
          i18n.language,
          data.prices.map((price) =>
            [
              price.title || '-',
              formatCurrency(i18n.language, price.price, data.currency),
            ].join(' '),
          ),
        ),
        status: i18n.t(`status.${data.status}`),
        trackInventory: data.trackInventory
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
        purchaseLimits:
          data.purchaseLimitPeriodType === 'none'
            ? i18n.t('common.no')
            : [
                i18n.t(
                  `shop.internal.purchaseLimit.${data.purchaseLimitPeriodType}`,
                ),
                data.purchaseLimit,
                i18n.t(
                  `recognition.allowanceRuleFrequency-${data.purchaseLimitPeriod}`,
                ),
              ].join(' '),
      };
    case 'hrChangedMarketplaceOrderStatus':
      return {
        orderNr: data.marketplaceOrderNr,
        employeeFullName: data.employeeFullName,
        status: i18n.t(`marketplaceOrders.status-${data.status}`),
      };
    case 'itemUpdatedFromExcel':
      return {
        status: data.status,
        file: data.fileId,
      };
    case 'parentCompanyGeneralSettingsChanged':
      return {
        defaultLanguage: formatLanguage(i18n.language, data.defaultLanguage),
        isEmailVisibleInternally: data.isEmailVisibleInternally
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
        isPersonalCodeRequiredForLogin: data.isPersonalCodeRequiredForLogin
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
      };
    case 'surveyCreated':
      return {
        name: data.surveyName,
      };
    case 'surveyEdited':
      return {
        name: data.name,
        publishDateTime: moment(data.publishDateTime).format('l LT'),
        endDateTime: moment(data.endDateTime).format('l LT'),
        anonymous: data.anonymous ? i18n.t('common.yes') : i18n.t('common.no'),
        emailNotificationsEnabled: data.emailNotificationsEnabled
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
        mobileNotificationsEnabled: data.mobileNotificationsEnabled
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
      };
    case 'surveyContentEdited':
      return {
        name: data.surveyName,
      };
    case 'surveyDeadlineEdited':
      return {
        name: data.name,
        endDateTime: moment(data.endDateTime).format('l LT'),
      };
    case 'surveyDeleted':
      return {
        name: data.surveyName,
      };
    default:
      return action satisfies never;
  }
};

export const getSanitizedTranslationParams = (entry: DataByAction) =>
  Object.fromEntries(
    Object.entries(getTranslationParams(entry)).map(([key, value]) => [
      key,
      [null, 'Invalid date'].includes(value) ? '-' : value,
    ]),
  );
